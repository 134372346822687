import React from "react"
import TextBlock from "../components/global/article/textBlock"
import Banner from "../components/global/article/banner"
import BannerImageWithText from "../components/global/article/bannerWithText"
import Layout from "../components/global/layout"
import headerBannerImage from "../images/sun-protection-hero.jpg"
import brickBanner from "../images/brick-banner.jpg"
import moisturiserProduct from "../images/product-and-package/inm.jpg"
import medPlusProduct from "../images/product-and-package/mp.jpg"
import ultraProtect from "../images/product-and-package/ul.jpg"
import crackedLipsImage from "../images/cracked-lips-image.png"
import paginationBanner from "../images/winter-lips-up-next.jpg"
import Header from "../components/global/header"
import SEO from "../components/global/seo"

const tempData = {
  hero: {
    image: headerBannerImage,
    text: (
      <>
        <h1>
          <span className="large-letter">S</span>un protection.
        </h1>
      </>
    ),
    align: "left",
  },
  firstComponent: {
    product: ultraProtect,
    productLink: "/ultra-lip-balm/",
    text: (
      <>
        <h2>sun care lip tips - how to take care of your lips from the sun</h2>
        <p style={{ marginBottom: "1.25rem" }}>
          with your active lifestyle, it’s unlikely you can completely avoid the
          sun. but as your lips are different and need special care versus the
          rest of your skin, here are some tips on how to protect your skin and
          lips when enjoying the outdoors:
        </p>
        <ul>
          <li>wear a large sunhat to help protect your face</li>
          <li>wear sunglasses to protect your eyes</li>
          <li>use sunscreen to protect your body’s skin</li>
          <li>
            do not lick your lips, the saliva acts like a lens to intensify sun
            exposure
          </li>
          <li>
            use a lip balm with at least a 15 SPF value and apply early and
            often throughout the day
          </li>
        </ul>
      </>
    ),
  },
  secondComponent: {
    image: brickBanner,
    text: (
      <>
        <h2>
          remedies for
          <span className="large">
            cracked <br /> & chapped
          </span>
        </h2>
        <h3>effective solutions to prevent dry lips</h3>
      </>
    ),
    align: "left",
  },
  thirdComponent: {
    product: moisturiserProduct,
    productLink: "/intensive-moisturiser/",
    text: (
      <>
        <h2>
          health conscious people like you want to protect themselves from the
          harmful sun rays.
        </h2>
        <p>
          there are a lot of terms used for sun protection, so let’s begin with
          the definitions.
        </p>
        <p>
          <strong>SPF:</strong> sun protection factor, a rating system to
          indicate the degree of protection from UVB radiation. the higher the
          factor number, the higher the level of protection. SPF can range from
          2 to 100. if you normally get a sunburn in 10 minutes, a SPF 15
          product extends that time by 15 times to 150 minutes before burning.
        </p>
        <p>
          <strong>UV Index:</strong> an abbreviation for ultraviolet index, an
          international scale derived by the Bureau of Meteorology to indicate
          the amount of UV radiation that reaches the Earth’s surface. the scale
          spans from 1 to 20; level 3 and above can begin to cause skin damage.
        </p>
        <p>
          <strong>UVA Rays:</strong> rays that can cause skin cells to age and
          cause long-term skin damage including wrinkles. according to the
          American Cancer Society, these rays are also thought to play a role in
          some skin cancers.
        </p>
        <p>
          <strong>UVB Rays:</strong> rays that can damage skin cells directly
          and can cause sunburns. according to the American Cancer Society,
          these rays are thought to cause most skin cancers.
        </p>
        <p>
          <strong>Epidermis:</strong> the outermost layer of skin which protects
          the body from bacteria, viruses, and infection. while it helps filter
          UV rays, it can’t completely stop UV damage, so you need to protect
          your skin.
        </p>

        <p>
          <strong>Dermis:</strong> layer beneath the epidermis and above the
          hypodermis. it controls the secretion of oils that serve as a
          protective barrier.
        </p>

        <p>
          <strong>Hypodermis:</strong> also known as the subcutaneous layer.
          lower, deepest layer of skin made of fat and connective tissue,
          providing insolation and fat storage.
        </p>
        <p>
          <strong>Melanin:</strong> natural skin pigmentation that helps provide
          some skin protection by filtering UV rays. it can’t completely stop UV
          damage, so you need to take steps to protect your skin.
        </p>
        <p>
          <strong>Collagen:</strong> a structural protein found in skin and
          other connective tissue. when the sun causes lips to burn, long
          lasting damage can occur. the collagen, for example, can be altered.
          collagen is the protein that gives lips body and resilience – and
          helps keep wrinkles from forming.
        </p>
        <p>
          <strong>Broad-spectrum sun protection:</strong> product that protects
          you from both types of UV rays, UVA and UVB, helping reduce the risk
          of skin cancer.
        </p>
      </>
    ),
  },
  forthComponent: {
    text:
      "the skin on our lips is much thinner than other parts of the body so it’s imperitive that they’re looked after",
    image: crackedLipsImage,
    roundal: "the lip experts",
    backgroundColor: "black",
  },
  //   fifthComponent: {
  //     product: ultraProtect,
  //     productLink: "/ultra-lip-balm/",
  //     text: (
  //       <>
  //         <h2>ski, sun, lips</h2>

  //         <p>
  //           if you’re off on a skiing trip over winter, put Blistex at the top of
  //           your packing list. The combination of wind, sun and cold on lips can
  //           be intense, so be prepared.
  //         </p>
  //         <p>
  //           make sure you’ve got sunscreen with a high SPF for your face, Blistex
  //           to protect your lips and a scarf to protect any exposed skin on your
  //           neck.
  //         </p>
  //         <p>
  //           if you start to get a burning feeling on your skin, it could be
  //           windburn. steer clear of hot showers and drinks and apply moisture to
  //           your face and lips.
  //         </p>
  //         <p>
  //           with over 70 years of experience, we know that when the cold hits it’s
  //           time to take lips seriously. Blistex is here to help you do just that.
  //         </p>
  //       </>
  //     ),
  //   },
  sixthComponent: {
    image: paginationBanner,
    articleLink: "/winter-lips",
    text: (
      <>
        <h2 className="small">winter lips that conquer the cold.</h2>
        <h2 className="large">up next.</h2>
      </>
    ),
    align: "center",
  },
}

const Article = () => {
  return (
    <>
      <SEO title="Sun Protection" />
      <Layout
        noHeaderInLayout={true}
        makeFooterSticky={false}
        whiteMenu={false}
      >
        <div className="header-hero-container header-hero-container--banner">
          <Header />
          <BannerImageWithText content={tempData.hero} color={"black"} />
        </div>

        <TextBlock content={tempData.firstComponent} />
        <BannerImageWithText content={tempData.secondComponent} />
        <TextBlock content={tempData.thirdComponent} />
        <Banner content={tempData.forthComponent} />
        {/* <TextBlock content={tempData.fifthComponent} /> */}
        <BannerImageWithText content={tempData.sixthComponent} />
      </Layout>
    </>
  )
}

export default Article
